import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { doGet, baseUrl } from "../../utils/requests";
import "./css/Carousel.css";

function BannerCarousel() {
  const [banners, setBanners] = useState([]);

  async function fetchData() {
    var response = await doGet("banners");
    setBanners(response.data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="carousel_size_carousel">
      <Carousel fade interval={3000}>
        {banners.map((banner, index) => (
          <Carousel.Item key={index}>
            <a href={banner.link}>
              <img
                className="d-block w-100 size_img"
                src={`${baseUrl}${banner.image}`}
              />
            </a>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default BannerCarousel;
