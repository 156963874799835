import React, { useEffect, useState } from "react";
import "./css/Blog_page.css";
import { Link, useParams } from "react-router-dom";
import { baseUrl, doGet } from "../../utils/requests";
import { Testimonial } from "../Testimonial/Testimonial";
import Form from "../Form/Form";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "antd";

export const Blog_page = ({ purpose }) => {
  const { id } = useParams();
  const [content, setContent] = useState({});

  async function getServiceContent() {
    var response = await doGet(`products/${id.replace(" ", "%20")}/`);
    onContentReceieved(response.data);
  }

  async function getBlogContent() {
    var response = await doGet(`blogs/${id.replace(" ", "%20")}/`);
    onContentReceieved(response.data);
  }

  async function getStoreContent() {
    var response = await doGet(`market/${id.replace(" ", "%20")}/`);
    onContentReceieved(response.data);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onContentReceieved(contentData) {
    if (window.innerWidth < 801) {
      console.log("CONTENT LOADED", content);
      let contentReplaced;
      let subcontentReplaced;
      if (contentData) {
        if (contentData.content) {
          contentReplaced = contentData.content.replaceAll("<p>&nbsp;</p>", "");
        }
        if (contentData.subcontent) {
          subcontentReplaced = contentData.subcontent.replaceAll(
            "<p>&nbsp;</p>",
            ""
          );
        }

        console.log(contentReplaced);
        console.log(subcontentReplaced);

        setContent({
          ...contentData,
          content: contentReplaced,
          subcontent: subcontentReplaced,
        });
      }
    } else {
      setContent(contentData);
    }
  }

  useEffect(() => {
    if (purpose === "services") {
      getServiceContent();
    } else if (purpose === "blog") {
      getBlogContent();
    } else if (purpose === "store") {
      getStoreContent();
    }
  }, [id]);

  return (
    <>
      <div className="faq_header">
        <p>{content.title}</p>
      </div>
      <div className={"page_content_wrapper"} style={{ padding: "0 30px" }}>
        <div className={"main_text__blog-page"}>
          {purpose === "store" ? (
            content.image2 != null ? (
              <Carousel style={{ paddingBottom: "30px" }}>
                <Carousel.Item>
                  {/* <img
                    className="d-block size_img"
                    src={`${baseUrl}${content.image1}`}
                    alt="img"
                  /> */}
                  <Image
                    className="d-block size_img"
                    src={`${baseUrl}${content.image1}`}
                    alt="img"
                    preview={{
                      mask: (
                        <div>
                          <p>Просмотр</p>
                        </div>
                      ),
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  {/* <img
                    className="d-block size_img"
                    src={`${baseUrl}${content.image2}`}
                    alt="img"
                  /> */}
                  <Image
                    className="d-block size_img"
                    src={`${baseUrl}${content.image2}`}
                    alt="img"
                    preview={{
                      mask: (
                        <div>
                          <p>Просмотр</p>
                        </div>
                      ),
                    }}
                  ></Image>
                </Carousel.Item>
              </Carousel>
            ) : (
              <div id="main_img" className="page_content_img_wrapper">
                {/* <img
                  className="page_content_img"
                  align="left"
                  vspace="5"
                  hspace="5"
                  src={`${baseUrl}${content.image1}`}
                  alt="img"
                /> */}
                <Image
                  className="d-block size_img"
                  src={`${baseUrl}${content.image1}`}
                  alt="img"
                  preview={{
                    mask: (
                      <div>
                        <p>Просмотр</p>
                      </div>
                    ),
                  }}
                />
              </div>
            )
          ) : (
            <div id="main_img" className="page_content_img_wrapper">
              <img
                className="page_content_img"
                src={`${baseUrl}${content.image}`}
                align="left"
                vspace="5"
                hspace="5"
                alt="img"
              />
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
        </div>
      </div>
      <div className={"page_content_wrapper"}>
        <div className={"main_text__blog-page"}>
          <div dangerouslySetInnerHTML={{ __html: content.subcontent }}></div>
        </div>
      </div>
      <div className={"content_tags_wrapper tags_wrapper"}>
        {(content.tags ?? []).map((tag, index) => (
          <Link
            key={index}
            to={`/${purpose}?category=` + tag.en_title}
            style={{ textDecoration: "none" }}
          >
            <div className="catalog_item_blog">{tag.title}</div>
          </Link>
        ))}
      </div>
      {purpose != "blog" ? (
        <div className={"page_content_wrapper"}>
          {(content.testimonials ?? []).length > 0 ? (
            <div>
              <div className="centered-title" style={{ margin: "0" }}>
                Отзывы
              </div>
              <div className="testimonials_wrapper">
                {(content.testimonials ?? []).map((testimonial, index) => (
                  <Testimonial
                    key={index}
                    name={testimonial.author}
                    text={testimonial.text}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div />
          )}
          <Form
            h2Text={
              purpose == "store"
                ? "Заказать / задать вопрос"
                : "Записаться на консультацию / задать вопрос"
            }
          />
        </div>
      ) : (
        <div />
      )}
    </>
  );
};
