import React from "react";
import "./css/Photo.css";

export const Photo = () => {
  return (
    <div className="wrapper_promo_img">
      <div className="wrapper_promo_img_text">
        <div className="main_text_promo_img">Анна Сокольникова</div>
        <div className="red_line_promo_img"></div>
        <div className="text_promo_img">
          Пространство возможностей вне суждений и ограничений.
        </div>
      </div>
    </div>
  );
};
