import React from "react";
import "./css/Coaching.css";
import "../../App.css";

export const Coaching = () => {
  return (
    <div className="wrapper_coaching container">
      {/* <div className="centered-title">Услуги</div> */}
      <div claass="row" style={{ marginTop: "60px" }}>
        <div className="text_coaching">
          В своих консультациях я применяю авторские техники, проверенные на
          себе, моих близких и клиентах. Трехлетняя практика показала, что мой
          уникальный подход помогает достигать результатов <br />
          быстро и эффективно.
        </div>
      </div>
    </div>
  );
};
