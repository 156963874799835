import React from "react";
import "./css/Reasons.css";

export const Reasons = () => {
  return (
    <div className="reasons container">
      <div className="container">
        <div className="wrapper_help_promo_text">
          <h2 className="centered-title">Вы выбираете лучшую жизнь?</h2>
          <p style={{ textAlign: "center" }}>
            Ответьте себе честно на вопрос: «Вы счастливы?» Если ответ
            отрицательный, пришла пора менять свою реальность. Я помогу вам
            раскрыть предназначение и жизненный путь, потенциал и возможности.
            Со мной вам не нужно тратить годы, чтобы найти себя и, наконец,
            начать жить так, как хочется. В моем поле трансформация вашей
            реальности произойдет быстро, естественно и комфортно.
          </p>
        </div>
        <div id="reasons">
          <div className="reason_row">
            <div className="reasons_block">
              <div className="reasons_round">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="240.000000pt"
                  height="240.000000pt"
                  viewBox="0 0 240.000000 240.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M1010 2385 c-165 -26 -327 -89 -475 -186 -103 -66 -268 -231 -334
-334 -267 -410 -267 -920 0 -1330 66 -103 231 -268 334 -334 477 -311 1089
-256 1494 134 421 406 492 1036 170 1530 -66 103 -231 268 -334 334 -256 167
-558 232 -855 186z m249 -424 c45 -32 66 -69 66 -120 0 -61 -24 -97 -81 -123
-94 -41 -194 23 -194 125 0 67 31 109 97 132 43 15 77 11 112 -14z m551 -146
c63 -95 100 -160 97 -170 -5 -11 -21 -15 -57 -15 l-50 0 0 -149 c0 -115 -3
-151 -14 -160 -9 -8 -48 -11 -112 -9 l-99 3 -3 158 -3 157 -55 0 c-39 0 -56 4
-60 15 -6 13 195 321 220 337 16 11 26 -1 136 -167z m-1059 -50 c85 -44 92
-172 13 -234 -38 -30 -83 -35 -132 -16 -96 39 -119 157 -44 227 15 14 34 28
42 31 30 11 92 7 121 -8z m589 -98 c13 -7 35 -28 47 -46 23 -34 23 -40 21
-235 l-3 -201 -37 -3 -38 -3 0 -102 c0 -145 -2 -147 -137 -147 -143 0 -143 0
-143 142 l0 108 -39 0 c-26 0 -41 5 -45 16 -10 26 -7 346 4 386 6 20 26 50 46
67 l35 31 132 0 c85 0 141 -5 157 -13z m-485 -218 c50 -44 56 -79 53 -284 l-3
-180 -42 -3 -43 -3 0 -109 c0 -106 -1 -109 -27 -130 -25 -19 -38 -21 -121 -18
-126 4 -132 11 -132 154 l0 103 -37 3 -38 3 -3 201 c-2 195 -2 201 21 235 35
52 57 59 204 59 l132 0 36 -31z m934 -178 c8 -5 11 -16 8 -25 -5 -13 -25 -16
-117 -16 -100 0 -110 2 -110 18 0 10 3 22 7 25 10 10 196 8 212 -2z m1 -86
c23 -28 0 -35 -111 -35 -101 0 -109 1 -109 19 0 26 11 29 116 30 69 1 95 -3
104 -14z m148 -87 c17 -17 17 -579 0 -596 -9 -9 -185 -12 -729 -12 -395 0
-724 3 -733 6 -13 5 -16 25 -16 110 l0 104 233 2 232 3 5 85 5 85 233 3 232 2
0 104 c0 56 3 106 7 109 12 13 518 8 531 -5z"
                    />
                    <path
                      d="M1152 1925 c-88 -37 -60 -165 36 -165 67 0 107 59 82 120 -14 34 -19
38 -59 50 -17 5 -39 3 -59 -5z"
                    />
                    <path
                      d="M1606 1802 c-42 -61 -76 -113 -76 -116 0 -3 18 -6 39 -6 26 0 41 -5
45 -16 3 -9 6 -81 6 -160 l0 -144 65 0 64 0 3 158 3 157 43 5 43 5 -75 109
c-42 60 -77 111 -80 113 -2 2 -38 -45 -80 -105z"
                    />
                    <path
                      d="M627 1710 c-50 -39 -42 -117 15 -146 87 -45 170 67 103 140 -30 32
-81 35 -118 6z"
                    />
                    <path
                      d="M1039 1604 c-23 -25 -24 -31 -27 -200 -3 -170 -3 -174 17 -174 20 0
21 6 21 89 0 66 4 90 14 94 32 12 36 -16 36 -225 l0 -208 30 0 30 0 0 108 c0
112 7 139 34 129 12 -5 16 -28 18 -124 l3 -118 30 0 30 0 3 215 c2 216 8 244
39 218 9 -7 13 -38 13 -94 0 -68 3 -84 15 -84 13 0 15 26 15 175 0 173 0 176
-25 200 -23 24 -30 25 -148 25 -120 0 -124 -1 -148 -26z"
                    />
                    <path
                      d="M535 1405 c-25 -24 -25 -27 -25 -200 0 -149 2 -175 15 -175 12 0 15
16 15 84 0 56 4 87 13 94 31 26 37 -2 39 -218 l3 -215 30 0 30 0 3 115 c3 119
10 142 37 120 12 -10 15 -36 15 -127 l0 -114 28 3 27 3 3 209 c2 209 8 241 38
229 11 -4 14 -26 14 -94 0 -83 1 -89 21 -89 20 0 20 4 17 174 -3 169 -4 175
-27 200 -24 25 -28 26 -148 26 -118 0 -125 -1 -148 -25z"
                    />
                    <path d="M1450 800 l0 -260 223 2 222 3 0 255 0 255 -222 3 -223 2 0 -260z" />
                    <path d="M980 690 l0 -150 210 0 210 0 0 150 0 150 -210 0 -210 0 0 -150z" />
                    <path d="M510 600 l0 -60 208 2 207 3 3 58 3 57 -211 0 -210 0 0 -60z" />
                  </g>
                </svg>
              </div>
              <div className="reasons_descr">
                {/* <div className="reasons_subtitle">Career, Work, Business</div> */}
                <div className="reasons_text">
                  Вы выйдите из состояния потерянности и увидете свой жизненный
                  путь
                </div>
              </div>
            </div>
            <div className="reasons_block">
              <div className="reasons_round">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="240.000000pt"
                  height="240.000000pt"
                  viewBox="0 0 240.000000 240.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M1000 2385 c-393 -62 -731 -317 -901 -680 -21 -44 -50 -121 -66 -170
-27 -88 -27 -95 -27 -335 0 -240 0 -247 27 -335 123 -395 411 -688 797 -809
434 -137 913 -13 1227 317 440 464 440 1190 0 1654 -270 284 -667 419 -1057
358z m36 -430 c82 -24 144 -102 158 -198 7 -43 9 -47 29 -41 30 9 31 9 12 37
-12 19 -15 45 -13 118 l3 94 185 3 c213 3 253 -4 304 -56 39 -38 56 -84 56
-149 0 -37 3 -43 21 -43 29 0 79 -53 79 -85 0 -54 -34 -95 -79 -95 -15 0 -19
-12 -24 -60 -8 -75 -33 -125 -86 -173 -38 -34 -41 -41 -41 -87 l0 -50 79 0
c90 0 124 -10 165 -48 51 -48 56 -73 56 -292 0 -180 -2 -205 -20 -240 -25 -49
-64 -77 -127 -90 -32 -7 -251 -10 -633 -8 -622 3 -603 2 -658 52 -50 45 -56
70 -60 256 -5 217 4 266 59 321 l39 39 0 290 c0 175 4 310 11 341 15 69 82
143 148 163 64 20 272 21 337 1z"
                    />
                    <path
                      d="M710 1903 c-35 -13 -72 -47 -90 -83 -19 -37 -20 -60 -20 -345 l0
-305 70 0 70 0 0 54 c0 47 -4 57 -35 90 -53 56 -66 116 -63 282 l3 139 222 3
223 2 6 -25 c3 -14 5 -87 2 -163 -3 -118 -7 -144 -27 -184 -12 -27 -34 -56
-47 -67 -16 -12 -24 -29 -24 -49 0 -28 2 -30 53 -34 28 -3 60 -9 70 -13 16 -7
17 10 17 282 0 280 -1 290 -22 333 -15 30 -37 53 -63 67 -36 21 -53 23 -185
22 -80 0 -152 -3 -160 -6z"
                    />
                    <path
                      d="M1270 1851 c0 -43 5 -65 19 -82 19 -24 23 -24 220 -27 l201 -3 0 41
c0 23 -7 54 -15 70 -28 53 -55 60 -250 60 l-175 0 0 -59z"
                    />
                    <path
                      d="M702 1547 c3 -140 4 -145 31 -180 37 -49 75 -67 140 -67 44 0 62 6
94 29 63 45 73 78 73 230 l0 131 -171 0 -170 0 3 -143z m113 33 c0 -28 -4 -35
-20 -35 -13 0 -21 8 -23 23 -5 34 5 54 25 50 13 -2 18 -13 18 -38z m150 0 c0
-28 -4 -35 -20 -35 -13 0 -21 8 -23 23 -5 34 5 54 25 50 13 -2 18 -13 18 -38z
m-135 -155 c7 -8 25 -15 40 -15 15 0 33 7 40 15 17 20 60 20 60 0 0 -22 -43
-63 -74 -71 -14 -3 -38 -3 -52 0 -31 8 -74 49 -74 71 0 20 43 20 60 0z"
                    />
                    <path
                      d="M1320 1572 c0 -106 3 -123 25 -168 35 -69 80 -98 164 -102 59 -3 71
0 112 26 73 47 89 91 89 240 l0 122 -195 0 -195 0 0 -118z m148 10 c2 -22 -2
-34 -12 -38 -34 -13 -61 37 -34 64 21 21 43 9 46 -26z m142 24 c18 -23 8 -61
-17 -64 -17 -3 -24 3 -29 22 -4 16 -1 32 6 41 16 19 24 19 40 1z m-130 -181
c7 -8 23 -15 36 -15 12 0 29 7 38 15 16 17 66 21 66 5 0 -5 -13 -24 -29 -42
-25 -28 -36 -33 -76 -33 -39 0 -51 5 -71 28 -13 15 -24 35 -24 43 0 19 44 18
60 -1z"
                    />
                    <path
                      d="M1227 1653 c-13 -12 -7 -51 9 -57 23 -9 34 2 34 35 0 21 -5 29 -18
29 -10 0 -22 -3 -25 -7z"
                    />
                    <path
                      d="M1770 1624 c0 -28 3 -35 18 -32 11 2 18 14 20 36 3 27 0 32 -17 32
-17 0 -21 -6 -21 -36z"
                    />
                    <path
                      d="M1200 1351 c0 -186 1 -193 18 -178 28 26 88 47 132 47 36 0 40 2 40
25 0 16 -14 37 -39 61 -54 48 -81 107 -81 176 l0 56 -35 4 -35 4 0 -195z"
                    />
                    <path
                      d="M798 1185 c-3 -60 -3 -60 -36 -63 -18 -2 -36 -10 -39 -18 -14 -36 53
-144 88 -144 5 0 9 21 9 48 1 63 29 122 79 162 24 20 41 42 41 54 0 19 -6 21
-70 21 l-69 0 -3 -60z"
                    />
                    <path
                      d="M1440 1223 c0 -14 12 -29 35 -43 19 -12 46 -40 59 -62 l24 -40 16 23
c10 15 16 44 16 84 l0 60 -75 0 c-70 0 -75 -1 -75 -22z"
                    />
                    <path
                      d="M984 1159 c-45 -13 -100 -78 -109 -127 -20 -121 73 -249 258 -352
l58 -32 62 31 c122 60 221 157 246 241 26 87 9 167 -45 209 -67 53 -158 47
-219 -14 -19 -19 -38 -35 -43 -35 -5 0 -19 12 -33 26 -27 30 -94 64 -124 63
-11 0 -34 -5 -51 -10z"
                    />
                    <path
                      d="M573 1105 c-49 -21 -73 -68 -73 -142 l0 -63 85 0 c84 0 85 0 85 25 0
20 5 25 25 25 23 0 25 -3 25 -49 0 -28 -4 -53 -10 -56 -5 -3 -10 -26 -10 -50
l0 -43 109 -4 c105 -3 111 -4 140 -32 41 -39 56 -87 42 -128 -6 -17 -11 -33
-11 -34 0 -2 43 -4 95 -4 94 0 95 0 95 24 0 20 -12 31 -67 60 -112 59 -222
161 -252 234 -9 20 -22 32 -41 36 -37 7 -109 72 -127 113 -7 18 -13 48 -13 68
0 33 -2 35 -32 35 -18 -1 -48 -7 -65 -15z"
                    />
                    <path
                      d="M1636 1102 c-4 -11 -20 -33 -35 -49 -22 -21 -30 -41 -35 -84 -16
-127 -111 -246 -265 -327 -67 -36 -81 -47 -81 -68 0 -24 1 -24 95 -24 l95 0
-10 26 c-15 39 -12 76 10 112 29 47 73 62 185 62 l95 0 0 44 c0 27 -6 48 -15
56 -11 9 -15 27 -13 54 3 36 6 41 28 41 18 0 26 -6 28 -22 3 -22 8 -23 88 -23
l84 0 0 56 c0 115 -42 156 -166 162 -76 4 -82 3 -88 -16z"
                    />
                    <path
                      d="M500 739 c0 -111 11 -144 60 -173 23 -13 57 -16 182 -16 149 0 155 1
176 23 27 29 28 71 2 97 -18 18 -33 20 -160 20 l-140 0 0 30 c0 17 5 30 10 30
6 0 10 20 10 45 l0 45 -70 0 -70 0 0 -101z"
                    />
                    <path
                      d="M1740 796 c0 -31 5 -46 16 -50 9 -3 14 -15 12 -28 -3 -22 -6 -23
-144 -26 -126 -2 -143 -5 -159 -22 -26 -29 -24 -79 4 -102 20 -16 42 -18 175
-18 134 0 155 2 184 20 47 29 62 73 62 180 l0 90 -75 0 -75 0 0 -44z"
                    />
                  </g>
                </svg>
              </div>
              <div className="reasons_descr">
                {/* <div className="reasons_subtitle">People and Relationships</div> */}
                <div className="reasons_text">
                  Обретете уверенность в себе, прилив сил, ресурсы и возможности
                </div>
              </div>
            </div>
          </div>
          <div className="reason_row">
            <div className="reasons_block">
              <div className="reasons_round">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="240.000000pt"
                  height="240.000000pt"
                  viewBox="0 0 240.000000 240.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M1010 2385 c-165 -26 -327 -89 -475 -186 -103 -66 -268 -231 -334
-334 -267 -410 -267 -920 0 -1330 66 -103 231 -268 334 -334 477 -311 1089
-256 1494 134 421 406 492 1036 170 1530 -66 103 -231 268 -334 334 -256 167
-558 232 -855 186z m-140 -430 c0 -21 -5 -25 -30 -25 -25 0 -30 4 -30 25 0 21
5 25 30 25 25 0 30 -4 30 -25z m456 6 c52 -24 111 -83 135 -135 16 -35 19 -67
19 -237 l0 -197 26 -6 c14 -4 37 -18 50 -31 23 -23 24 -29 24 -177 l0 -153 46
-77 c44 -77 45 -78 86 -78 29 0 49 -7 65 -22 29 -28 31 -80 3 -108 -16 -16
-18 -25 -10 -40 23 -44 -1 -110 -47 -128 -15 -7 -138 -27 -272 -45 l-244 -33
-233 32 c-289 40 -289 40 -314 71 -21 27 -27 87 -11 112 6 10 3 20 -12 31 -15
12 -21 29 -21 55 0 49 30 75 89 75 l43 0 46 81 46 82 0 147 c0 132 2 150 20
173 11 13 31 27 44 31 l24 6 4 187 c4 209 15 252 81 322 73 77 217 106 313 62z
m574 -6 c0 -20 -5 -25 -26 -25 -21 0 -25 -4 -22 -22 2 -15 11 -24 26 -26 34
-5 31 -52 -3 -52 -20 0 -25 5 -25 25 0 20 -5 25 -25 25 -20 0 -25 5 -25 25 0
20 5 25 25 25 20 0 25 5 25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m-140 -50
c0 -21 -5 -25 -30 -25 -25 0 -30 4 -30 25 0 21 5 25 30 25 25 0 30 -4 30 -25z
m-1190 -44 c0 -13 8 -21 23 -23 16 -2 23 -11 25 -30 3 -24 0 -28 -22 -28 -21
0 -26 -5 -26 -26 0 -22 -4 -25 -27 -22 -20 2 -29 9 -31 25 -2 14 -11 23 -25
25 -32 4 -31 52 1 56 14 2 22 10 22 23 0 14 7 19 30 19 23 0 30 -5 30 -19z
m170 -326 c0 -20 5 -25 25 -25 20 0 25 -5 25 -25 0 -20 -5 -25 -25 -25 -20 0
-25 -5 -25 -25 0 -20 -5 -25 -25 -25 -20 0 -25 5 -25 25 0 20 -5 25 -25 25
-34 0 -37 47 -2 52 14 2 23 11 25 26 5 34 52 31 52 -3z m-150 -50 c0 -20 -5
-25 -25 -25 -20 0 -25 5 -25 25 0 20 5 25 25 25 20 0 25 -5 25 -25z m1168 -22
c2 -15 10 -23 23 -23 14 0 19 -7 19 -25 0 -18 -5 -25 -20 -25 -15 0 -20 -7
-20 -25 0 -21 -5 -25 -30 -25 -25 0 -30 4 -30 25 0 20 -5 25 -25 25 -20 0 -25
5 -25 25 0 20 5 25 25 25 20 0 25 5 25 26 0 22 4 25 28 22 19 -2 28 -9 30 -25z"
                    />
                    <path
                      d="M1139 1917 c-61 -23 -97 -55 -125 -113 -15 -30 -24 -57 -21 -60 13
-13 123 32 179 73 63 46 88 49 88 11 0 -60 67 -118 136 -118 l37 0 -7 43 c-9
54 -46 110 -95 143 -48 31 -138 41 -192 21z"
                    />
                    <path
                      d="M1173 1750 c-23 -15 -67 -35 -98 -45 -49 -15 -55 -20 -49 -39 20 -68
94 -137 162 -151 52 -11 136 35 173 94 l30 46 -33 6 c-63 11 -121 55 -139 105
-2 6 -21 0 -46 -16z"
                    />
                    <path
                      d="M1415 1590 c-3 -10 -28 -39 -56 -64 -45 -41 -49 -48 -49 -90 l0 -46
60 0 60 0 0 110 c0 109 -3 128 -15 90z"
                    />
                    <path
                      d="M990 1493 l0 -103 60 0 60 0 0 43 c0 40 -5 49 -60 104 l-60 60 0
-104z"
                    />
                    <path
                      d="M1158 1403 c-3 -58 -3 -58 -36 -61 -52 -5 -37 -32 31 -55 56 -18 58
-18 115 0 69 24 85 53 28 53 l-36 0 0 60 0 60 -50 0 -49 0 -3 -57z"
                    />
                    <path
                      d="M974 1323 c3 -10 24 -92 46 -183 23 -91 45 -182 51 -202 l10 -38 128
0 129 0 51 205 c28 112 51 212 51 220 0 12 -8 15 -27 13 -23 -2 -29 -9 -33
-37 -5 -33 -8 -35 -88 -62 l-82 -28 -78 26 c-93 31 -102 37 -102 74 0 26 -4
29 -31 29 -24 0 -29 -3 -25 -17z"
                    />
                    <path
                      d="M902 1318 c-8 -8 -12 -61 -12 -162 l-1 -151 -52 -90 -53 -90 -54 -5
c-47 -4 -55 -8 -55 -25 0 -18 8 -20 75 -23 l76 -3 90 102 91 102 -44 176 c-25
97 -46 177 -47 178 -1 2 -8 -3 -14 -9z"
                    />
                    <path
                      d="M1491 1293 c-73 -277 -80 -317 -63 -338 9 -11 51 -57 91 -103 l75
-83 75 3 c68 3 76 5 76 23 0 17 -8 20 -52 23 -59 3 -62 6 -126 123 l-37 66 0
150 c0 119 -3 153 -14 163 -12 9 -17 5 -25 -27z"
                    />
                    <path
                      d="M947 826 c-37 -41 -67 -77 -67 -80 0 -3 8 -6 19 -6 19 0 131 125 131
147 0 25 -17 12 -83 -61z"
                    />
                    <path
                      d="M1390 886 c0 -24 110 -146 132 -146 24 0 20 8 -56 92 -62 69 -76 79
-76 54z"
                    />
                    <path
                      d="M1027 788 c-54 -62 -57 -60 88 -77 95 -12 186 -7 297 15 l28 6 -47
54 -47 54 -137 0 -137 0 -45 -52z"
                    />
                    <path
                      d="M712 694 c-28 -20 -28 -50 1 -68 36 -22 491 -79 557 -69 l54 8 -49
32 c-75 48 -85 50 -300 78 -110 14 -209 28 -221 30 -11 3 -30 -2 -42 -11z"
                    />
                    <path
                      d="M1483 682 c-101 -14 -183 -28 -183 -32 0 -3 25 -21 55 -39 l55 -33
142 18 c106 13 147 23 160 36 10 10 18 23 18 28 0 15 -38 50 -52 49 -7 -1 -95
-13 -195 -27z"
                    />
                    <path
                      d="M520 1810 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"
                    />
                    <path
                      d="M690 1484 c0 -21 4 -25 23 -22 14 2 23 11 25 26 3 18 -1 22 -22 22
-21 0 -26 -5 -26 -26z"
                    />
                    <path
                      d="M1710 1410 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"
                    />
                  </g>
                </svg>
              </div>
              <div className="reasons_descr">
                {/* <div className="reasons_subtitle">Emotional and Physical Well-being</div> */}
                <div className="reasons_text">
                  Перестанете подстраиваться под других и начнете выбирать свои
                  желания. Двигаться к целям станет легко
                </div>
              </div>
            </div>
            <div className="reasons_block">
              <div className="reasons_round">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="240.000000pt"
                  height="240.000000pt"
                  viewBox="0 0 240.000000 240.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M1010 2385 c-165 -26 -327 -89 -475 -186 -103 -66 -268 -231 -334
-334 -267 -410 -267 -920 0 -1330 66 -103 231 -268 334 -334 477 -311 1089
-256 1494 134 421 406 492 1036 170 1530 -66 103 -231 268 -334 334 -256 167
-558 232 -855 186z m91 -443 c55 -62 37 -116 -67 -202 l-74 -61 -74 62 c-42
35 -80 75 -87 91 -21 52 9 123 61 140 8 3 59 4 114 4 98 -2 99 -3 127 -34z
m474 8 c30 -28 41 -80 26 -118 -7 -16 -45 -56 -87 -91 l-74 -62 -74 61 c-100
82 -120 136 -75 194 30 39 51 44 161 42 87 -2 100 -5 123 -26z m-950 -220 c6
0 28 3 51 6 36 5 46 2 78 -26 32 -28 36 -38 36 -78 0 -43 -4 -50 -56 -99 -30
-29 -68 -62 -84 -73 l-28 -20 -78 68 c-85 73 -101 105 -83 160 15 46 62 74
114 68 22 -3 45 -6 50 -6z m1150 0 c6 0 26 3 46 6 49 8 96 -17 115 -62 24 -57
8 -90 -80 -166 l-78 -68 -28 20 c-16 11 -54 44 -84 73 -52 49 -56 56 -56 99 0
40 4 50 36 78 32 28 42 31 78 26 23 -3 46 -6 51 -6z m-446 -76 c64 -19 131
-77 160 -138 17 -38 20 -62 19 -182 -1 -162 -7 -178 -72 -195 -34 -8 -47 -18
-56 -40 -7 -16 -26 -40 -42 -54 -22 -19 -27 -29 -20 -41 8 -14 36 7 170 132
88 82 165 162 172 177 30 66 97 85 148 42 17 -14 41 -25 55 -25 39 0 56 -10
73 -42 25 -48 -3 -102 -60 -113 -17 -4 -39 -9 -48 -11 -10 -3 -90 -83 -178
-177 l-160 -172 0 -162 0 -163 -290 0 -290 0 0 163 0 162 -160 172 c-88 94
-168 174 -178 177 -9 2 -31 7 -48 11 -57 11 -85 65 -60 113 17 32 34 42 73 42
14 0 38 11 55 25 51 43 118 24 148 -42 7 -15 84 -95 172 -177 136 -126 162
-146 170 -132 7 13 3 22 -17 37 -14 12 -35 38 -45 60 -17 32 -26 39 -51 39
-19 0 -38 9 -53 24 -21 23 -22 34 -23 167 -2 122 1 148 18 184 28 60 89 115
151 136 67 23 195 25 267 3z"
                    />
                    <path
                      d="M860 1910 c-32 -32 -24 -55 38 -109 31 -28 60 -51 63 -51 3 0 32 24
64 53 52 47 57 56 51 80 -10 41 -50 56 -88 34 -27 -15 -32 -15 -51 -2 -29 20
-53 19 -77 -5z"
                    />
                    <path
                      d="M1340 1910 c-29 -29 -25 -52 18 -91 86 -80 75 -78 136 -25 30 26 58
55 61 66 15 49 -44 86 -90 56 -23 -15 -28 -15 -47 -1 -30 20 -54 19 -78 -5z"
                    />
                    <path
                      d="M532 1674 c-40 -28 -29 -65 34 -118 63 -51 63 -51 132 13 51 47 55
85 10 110 -25 14 -29 14 -55 -6 -28 -20 -29 -20 -52 -2 -28 23 -40 24 -69 3z"
                    />
                    <path
                      d="M1682 1674 c-35 -24 -28 -61 21 -106 68 -63 68 -63 132 -11 49 40 56
50 53 77 -2 21 -11 35 -30 45 -25 14 -29 14 -55 -6 -28 -20 -29 -20 -52 -2
-28 23 -40 24 -69 3z"
                    />
                    <path
                      d="M1045 1587 c-76 -43 -122 -148 -101 -231 8 -34 36 -34 36 -1 0 58 34
95 108 115 63 17 116 -2 176 -61 52 -52 83 -61 110 -33 9 8 16 24 16 35 0 14
7 19 26 19 24 0 26 -3 22 -32 -3 -18 -8 -41 -13 -50 -5 -13 -3 -18 9 -18 40 0
33 131 -11 193 -50 71 -89 87 -223 87 -101 0 -120 -3 -155 -23z"
                    />
                    <path
                      d="M1059 1401 c-21 -17 -23 -25 -20 -97 4 -124 17 -174 53 -211 90 -93
233 -45 258 86 5 30 10 69 10 87 0 29 -4 34 -22 34 -31 0 -83 33 -104 65 -35
54 -129 73 -175 36z m239 -195 c4 -36 -15 -83 -42 -101 -24 -17 -79 -20 -107
-5 -36 20 -65 111 -42 134 4 4 48 5 98 4 l90 -3 3 -29z"
                    />
                    <path
                      d="M1162 1168 c8 -22 37 -34 55 -22 33 21 26 34 -17 34 -28 0 -40 -4
-38 -12z"
                    />
                    <path
                      d="M613 1306 c-16 -14 -45 -26 -64 -28 -26 -2 -35 -8 -37 -24 -2 -15 4
-23 20 -28 47 -12 74 -5 117 32 41 36 42 39 26 55 -22 22 -28 22 -62 -7z"
                    />
                    <path
                      d="M1725 1313 c-16 -16 -15 -19 26 -55 43 -37 70 -44 117 -32 16 5 22
13 20 28 -2 16 -11 22 -37 24 -19 2 -48 14 -64 28 -34 29 -40 29 -62 7z"
                    />
                    <path
                      d="M952 1268 c-25 -25 -8 -78 25 -78 9 0 13 15 13 45 0 46 -13 58 -38
33z"
                    />
                    <path
                      d="M1410 1235 c0 -46 13 -58 38 -33 25 25 8 78 -25 78 -9 0 -13 -15 -13
-45z"
                    />
                    <path
                      d="M672 1211 l-34 -29 161 -172 161 -172 0 -149 0 -149 240 0 240 0 0
149 0 149 161 172 161 172 -34 29 c-18 15 -38 28 -43 29 -6 0 -84 -68 -173
-151 l-162 -151 0 -94 c0 -52 -4 -94 -8 -94 -5 0 -17 9 -28 21 -59 66 -169 66
-228 0 -11 -12 -23 -21 -28 -21 -4 0 -8 42 -8 94 l0 94 -162 151 c-89 83 -167
151 -173 151 -5 -1 -25 -14 -43 -29z"
                    />
                    <path
                      d="M1123 972 c-5 -23 0 -31 55 -68 20 -15 24 -15 45 0 54 37 59 45 54
68 -6 21 -11 23 -77 23 -66 0 -71 -2 -77 -23z"
                    />
                    <path
                      d="M1110 870 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
                    />
                    <path
                      d="M1270 870 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
                    />
                  </g>
                </svg>
              </div>
              <div className="reasons_descr">
                {/* <div className="reasons_subtitle">Personal growth</div> */}
                <div className="reasons_text">
                  Желаемое начнет приходить в вашу жизнь без напряжения и
                  надрыва
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
