import React from "react";
import "../../App.css";

export const Block_about_first = () => {
  return (
    <div className={"wrapper_section_about_img"}>
      <div className={"wrapper_text_section_about"}>
        {/* <h2>Немного обо мне</h2> */}
        <p style={{ textAlign: "center" }}>
          Более 7 лет я изучаю и практикую методы психологии, коучинга,
          изменения подсознания и смены мышления. Зачем? Чтобы жить так, как я
          хочу, и помогать в этом другим. Для меня было важно пройти свой путь
          трансформаций быстро и максимально безболезненно. Хотелось чувствовать
          себя счастливой, быть в гармоничных отношениях, заниматься любимым
          делом и иметь деньги уже сейчас, а не через 10 лет. После исследования
          многих методов я создала свои авторские техники и пришла к тому, чего
          желала. Мой уникальный подход помогает достигать результатов
          стремительно и эффективно. Проверено на себе, близких, клиентах и даже
          на моем ребенке. Со мной вам не нужно годами работать над собой,
          чтобы, наконец, создать счастливую и изобильную жизнь. В моем
          пространстве трансформация вашей реальности актуализируется быстро,
          комфортно и гармонично.
        </p>
      </div>
      {/* <div className={"wrapper_img_about"}>
                <img src={require("../../pages/about_img/IMG_8578.JPG")} alt=""/>
            </div> */}
    </div>
  );
};
