import React from "react";
import "./css/Photo_about.css";

export const Photo_about = () => {
  return (
    <>
      <div className={"wrapper"}>
        <h1
          className={"main_text_promo_about_img"}
          style={{ marginBottom: "45px" }}
        >
          Меня зовут Анна Сокольникова.
        </h1>
        <div className={"text_promo_img container"}>
          Я сертифицированный энергопрактик международных методик Access
          Consciousness и ThetaHealing, коуч, таролог, рунолог, ченнелер,
          специалист метода диагностики «Матрица Судьбы».
        </div>
        <div className={"text_promo_img container"}>
          Моя специализация – раскрывать предназначение и жизненный путь,
          потенциал и возможности.
        </div>
      </div>
    </>
  );
};
