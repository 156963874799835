import React, { useState, useEffect, lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
import { doGet } from "../../utils/requests";

const LazyNavbar = lazy(() => import("../LazyNavbar/LazyNavbar"));

function NaviBar() {
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [canonical, setCanonical] = useState("");

  async function getMeta(url) {
    try {
      // let response = await doGet(
      //   "meta/" + (url === "/" ? "main" : url.replaceAll("/", "$").slice(1))
      // );
      let key = url === "/" ? "main" : url.replaceAll("/", "$").slice(1);
      let found_meta = meta.data.find((obj) => obj.key.replaceAll("/", "$") === key);
      if (found_meta) {
        setTitle(found_meta["title"]);
        setDescription(found_meta["description"]);
        setCanonical(found_meta["canon"])
      }
    } catch (e) {
      if (location.pathname.includes("store/")) {
        var response = await doGet(
          `market/${location.pathname.split("store/")[1]}`
        );
        setTitle(response["data"]["title"]);
        setDescription(
          `${response["data"]["title"]} - купить по выгодной цене в России от Анны Сокольниковой`
        );
        setCanonical(response["data"]["canon"])
        console.log(response);
      }
      console.log(e + " META ERROR");
    }
  }

  useEffect(() => {
    getMeta(location.pathname + location.search);
  }, [location]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 100);
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyNavbar title={title} description={description} isOpen={isOpen} canonical={canonical}/>
    </Suspense>
  );
}

export default NaviBar;

let meta = {
  data: [
    {
      id: 2,
      key: "main",
      title:
        "Энергетический коуч Анна Сокольникова: консультации и сессии в России",
      description:
        "Усулги энергетического коуча Анны Сокольниковой по личностому развитию и другому. Коучинг по бизнесу и личной жизни в России.",
      canon: "https://annasokolnikova.ru/",
    },
    {
      id: 3,
      key: "services",
      title:
        "Услуги энергетического коуча: запись к Анне Сокольниковой",
      description:
        "Полный список услуг энергопрктика Анны Сколькниковой. Запишитесь на личные или групповые сеансы к коучу в России.",
      canon:
        "https://annasokolnikova.ru/services",
    },
    {
      id: 4,
      key: "blog?category=otnosheniia-roditelei-i-detei",
      title:
        "Отношения в семье от Анны Сокольниковой: конфликты родителей и детей и другое",
      description:
        "Узнайте все об отношениях родителей и детей из блога Анны Сокольниковой. Статьи на тему конфликтов с детьми в России.",
      canon:
        "https://annasokolnikova.ru/blog?category=otnosheniia-roditelei-i-detei",
    },
    {
      id: 5,
      key: "service/konsultacii",
      title:
        "Консультации от Анны Сокольниковой: цена онлайн сеанса в России",
      description:
        "Получите онлайн консультацию от Анны Сокольниковой по оптимальной цене. Запишитесь на личную консультацию в России.",
      canon:
        "https://annasokolnikova.ru/service/konsultacii",
    },
    {
      id: 6,
      key: "service/nastavnichestvo",
      title:
        "Наставничество Анны Сокольниковой: цена программы в России",
      description:
        "Заказать программу наставничества у Анны Сокольниковой по выгодной цене. Прокачайте свои сильные качества с наставничеством в России.",
      canon: "https://annasokolnikova.ru/service/nastavnichestvo",
    },
    {
      id: 7,
      key: "service/diagnostika-matritsa-sudby",
      title:
        "Матрица судьбы от Анны Сокольниковой: цена разбора и анализа",
      description:
        "Разбор матрицы судьбы от Анны Сокольниковой по выгодной цене. Анализ денежного канала, канала отношений и совместимости в России.",
      canon: "https://annasokolnikova.ru/service/diagnostika-matritsa-sudby",
    },
    {
      id: 8,
      key: "service/seans-taro",
      title:
        "Расклад таро от Анны Сокольниковой: цена услуги в России",
      description:
        "Расклад таро на отношения, ситуацию, деньги от Анны Сокольниковой. Закажите подробный расклад по Вашему запросу недорого в России.",
      canon: "https://annasokolnikova.ru/service/seans-taro",
    },
    {
      id: 9,
      key: "service/neiromeditatsiia",
      title:
        "Нейромедитация от Анны Сокольниковой: цена сеанса в России",
      description:
        "Закажите сеанс нейромедитации от Анны Сокольниковой по выгодной цене! Узнайте о пользе данной услуги именно для Вас от сертифицированного энергопрактика.",
      canon: "https://annasokolnikova.ru/service/neiromeditatsiia",
    },
    {
      id: 10,
      key: "service/runicheskii-seans",
      title:
        "Рунический сеанс от Анны Сокольниковой: как работают руны и каких результатов можно добиться",
      description:
        "Выгодный сеанс расклада рун от Анны Сокольниковой онлайн в России. Закажите рунический сеанс и узнайте, чем они могут помочь Вам в раскрытии потенциала.",
      canon: "https://annasokolnikova.ru/service/runicheskii-seans",
    },
    {
      id: 11,
      key: "service/pismo-dushi",
      title:
        "Письмо Души от Анны Сокольниковой: онлайн сеанс в России",
      description:
        "Запишитесь на составления и разбор Письма Души с Анной Сокольниковой. Онлайн сеанс услуги Письмо Души с энергетическим практиком в России.",
      canon: "https://annasokolnikova.ru/service/pismo-dushi",
    },
    {
      id: 12,
      key: "service/seans-access-bars",
      title:
        "Access Bars от Анны Сокольниковой: цена сеанса в Москве",
      description:
        "Запишитесь на сеанс Access Bars от энегопрактика Анны Сокольниковой. Проведение массажа Access Bars по выгодной цене в Москве.",
      canon: "https://annasokolnikova.ru/service/seans-access-bars",
    },
    {
      id: 13,
      key: "service/seans-access-facelift",
      title:
        "Сеанс Access Facelift от Анны Сокольниковой: цена услуги в Москве",
      description:
        "Сеанс Access Facelift от сертифицированного энергопрактика Анны Сокольниковой. Запишитесь на массаж Access Facelift выгодно в Москве.",
      canon: "https://annasokolnikova.ru/service/seans-access-facelift",
    },
    {
      id: 14,
      key: "service/pakety-uslug",
      title:
        "Пакеты услуг Анны Сокольниковой в России: комплекс услуг по выгодной цене",
      description:
        "Пакеты услуг от Анны Сокольниковой по выгодным ценам в России. Желаемая трансформация вашей реальности произойдет быстрее и легче с комплексным подходом!",
      canon: "https://annasokolnikova.ru/service/pakety-uslug",
    },
    {
      id: 15,
      key: "service/dlia-korporativnykh-klientov",
      title:
        "Услуги для корпоративных клиентов от Анны Сокольниковой в России",
      description:
        "Список услуг для корпоративных клиентов от анны Сокольниковой. Услуги на сотрудников и руководителей от энергепрактика в Москве.",
      canon: "https://annasokolnikova.ru/service/dlia-korporativnykh-klientov",

    },
    {
      id: 16,
      key: "services?category=marafony-i-kursy",
      title:
        "Марафоны и курсы от Анны Сокольниковой: открытые и бесплатные марафоны",
      description:
        "Марафоны и курсы от Анны Сокольниковой в России.",
      canon: "https://annasokolnikova.ru/services?category=marafony-i-kursy",
    },
    {
      id: 17,
      key: "service/marafony-i-kursy",
      title:
        "Марафон Деньги, приходите! от Анны Сокольниковой в России",
      description:
        "Участвуйте в марафоне Анны Сокольниковой - Деньги, приходите!. Ваша финансовая реальность изменится благодаря получении ясности в сфере денег.",
      canon: "https://annasokolnikova.ru/service/marafony-i-kursy",
    },
    {
      id: 18,
      key: "service/ya-est-izobilie",
      title:
        "Марафон Я есть изобилие! от Анны Сокольниковой в России",
      description:
        "Поучаствуйте в марафоне Анны Сокольниковой Я есть изобилие!. Заметьте и признайте свое богатство и получите его!",
      canon: "https://annasokolnikova.ru/service/ya-est-izobilie",
    },
    {
      id: 19,
      key: "service/poleznoe-v-podarok",
      title:
        "Полезные подарки от Анны Сокольнинковой: бесплатные онлайн медитации и другое",
      description:
        "Получите полезные материалы от сертифицированного специалиста Анны Сокольниковой совершенно бесплатно! Подарки от энергопрактика в России.",
      canon: "https://annasokolnikova.ru/service/poleznoe-v-podarok",
    },
    {
      id: 20,
      key: "store",
      title:
        "Онлайн магазин Анны Сокольниковой в России: свечи, талисманы, украшения по выгодной цене",
      description:
        "Купить качественные украшения, свечи и талисманы по выгодным ценам от Анны Сокольниковой. Ощутите спокойствие и уверенность от приобретенных покупок!",
      canon: "https://annasokolnikova.ru/store",
    },
    {
      id: 21,
      key: "store?category=svechi",
      title: "Энергетические свечи от Анны Сокольниковой",
      description:
        "Выберите свечу с травами, подходящую именно Вам и Вашим запросам, усильте намерение на осуществление вашего желания с Анной Сокольниковой!",
    },
    {
      id: 22,
      key: "store?category=nabory-svechei",
      title:
        "Наборы свечей от Анны Сокольниковой по низким ценам",
      description:
        "Закажите наборы энергетических свечей ручной работы от Анны Сокольниковой в России.",
      canon: "https://annasokolnikova.ru/store?category=svechi",
    },
    {
      id: 23,
      key: "store?category=ukrasheniia",
      title:
        "Украшения с натуральными камнями от Анны Сокольниковой",
      description:
        "Украшения от Анны Сокольниковой с натуральными камнями по выгодной цене в России. Вы будете в центре внимания и соберете миллион комплиментов!",
      canon: "https://annasokolnikova.ru/store?category=ukrasheniia",
    },
    {
      id: 24,
      key: "store?category=sergi",
      title:
        "Серьги с натуральными камнями от Анны Сокольниковой по выгодной цене",
      description:
        "Закажите серьги из натуральных камней от Анны Сокольниковой. Серьги - энергетические помощники по выгодной цене в России.",
      canon: "https://annasokolnikova.ru/store?category=sergi",
    },
    {
      id: 25,
      key: "store?category=kolye",
      title:
        "Колье с натуральными камнями от Анны Сокольниковой по выгодной цене",
      description:
        "Закажите колье с натуральными камнями ручной работы от Анны Сокольниковой. Колье - талисман по выгодной цене в России.",
      canon: "https://annasokolnikova.ru/store?category=kolye",
    },
    {
      id: 26,
      key: "store?category=braslety",
      title:
        "Браслеты с натуральными камнями от Анны Сокольниковой по выгодной цене",
      description:
        "Купите браслеты из натуральных минералов от энергопрактика Анны Сокольниковой. Браслеты ручной работы по низким ценам в России.",
      canon: "https://annasokolnikova.ru/store?category=braslety",
    },
    {
      id: 27,
      key: "store?category=komplekty",
      title:
        "Комплекты украшений с натуральными камнями от Анны Сокольниковой",
      description:
        "Купите комплекты украшений с натуральными камнями от Анны Сокольниковой по выгодной цене в России. Найдите свою пару, в которой будете чувствовать себя уверенной!",
      canon: "https://annasokolnikova.ru/store?category=komplekty",
    },
    {
      id: 28,
      key: "store?category=muzhskaia-kollektsiia",
      title:
        "Мужская коллекция от Анны Сокольниковой: браслеты с натуральными камнями",
      description:
        "Закажите браслеты с натуральными камнями для мужчин от Анны Сокольниковой по оптимальной цене. Мужские талисманы ручной работы в России.",
      canon: "https://annasokolnikova.ru/store?category=muzhskaia-kollektsiia",
    },
    {
      id: 29,
      key: "about",
      title:
        "Анна Сокольникова: сертифицированный энергопрактик, коуч, таролог",
      description:
        "Узнайте больше об Анне Сокольниковой — сертифицированном энергопрактике: личный опыт, успешный жизненный путь, уверенность в себе.",
      canon: "https://annasokolnikova.ru/about",
    },
    {
      id: 30,
      key: "contact",
      title: "Контакты Анны Сокольниковой в России",
      description:
        "Остались вопросы? Вы можете связаться c Анной Сокольниковой.",
      canon: "https://annasokolnikova.ru/contact",
    },
    {
      id: 31,
      key: "testimonials",
      title: "Отзывы  реальных клиентов о работе Анны Сокольниковой",
      description:
        "Отзывы клиентов энергопрактика Анны Сокольниковой: для Вашей же уверенности - все положительные слова о работе сертифицированного специалиста.",
      canon: "https://annasokolnikova.ru/testimonials",
    },
    {
      id: 32,
      key: "blog",
      title:
        "Блог энергопрактика Анны Сокольниковой: статьи, новости и полезные советы",
      description:
        "Полезные и интересные статьи от Анны Сокольниковой в России: прошлый опыт, актуальные проблемы и ситуации, в которых Вы можете узнать себя.",
      canon: "https://annasokolnikova.ru/blog",
    },
    {
      id: 33,
      key: "blog?category=otnoshenie-k-sebe",
      title:
        "Блог об отношении к себе от Анны Сокольниковой: самоценность и уверенность в себе",
      description:
        "Как стать уверенным и начать доверять самому себе? Анна Сокольникова подробно раскроет темы в данной сфере!",
      canon: "https://annasokolnikova.ru/blog?category=otnoshenie-k-sebe",
    },
    {
      id: 34,
      key: "blog?category=otnosheniia-s-partnerom",
      title:
        "Блог об отношениях с партнером от Анны Сокольниковой: конфликты и измены",
      description:
        "Анна Сокольникова разберет темы о созависимости в отношениях, поможет справить с тяжелым эмоциональным состоянием после расставания и кризисом в отношениях!",
      canon: "https://annasokolnikova.ru/blog?category=otnosheniia-s-partnerom",
    },
    {
      id: 35,
      key: "blog?category=otnosheniia-s-rodovoi-sistemoi",
      title:
        "Блог об отношениях с родовой системой от Анны Сокольниковой: конфликты и ссоры",
      description:
        "Анна Сокольникова пишет об отношениях с родовой системой. Взаимное противостояние и неприятные родственники - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog?category=otnosheniia-s-rodovoi-sistemoi",
    },
    {
      id: 36,
      key: "blog?category=lichnostnoe-razvitie",
      title:
        "Блог о личностном развитии от Анны Сокольниковой: избавление от тревоги и принятие себя",
      description:
        "Анна Сокольникова раскрывает темы освобождения от чувства вины, возрастных кризисов и повышения качества жизни - читать статьи онлайн.",
      canon: "https://annasokolnikova.ru/blog?category=lichnostnoe-razvitie",
    },
    {
      id: 37,
      key: "blog?category=prednaznachenie-i-put",
      title:
        "Блог о поиске предназначения: поиск себя  от Анны Сокольниковой",
      description:
        "Сертифицированный энергопрактик Анна Сокольникова о личном опыте поиска собственного предназначения и пути в жизни.",
      canon: "https://annasokolnikova.ru/blog?category=prednaznachenie-i-put",
    },
    {
      id: 38,
      key: "blog?category=resursnoe-sostoianie",
      title:
        "Блог о ресурсном состоянии: любовь к себе от Анны Сокольниковой",
      description:
        "Энергопрактик Анна Сокольникова об изменении восприятия различных ситуаций, тренировке мышления и любви к себе - читать статьи онлайн.",
      canon: "https://annasokolnikova.ru/blog?category=resursnoe-sostoianie",
    },
    {
      id: 39,
      key: "blog?category=dengi-i-samorealizatsiia",
      title:
        "Блог о деньгах и самореализации от Анны Сокольниковой",
      description:
        "Анна Сокольникова о том, как раскрыть таланты, проработать страхи идти в новое и улучшить финансовую реальность - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog?category=dengi-i-samorealizatsiia",
    },
    {
      id: 40,
      key: "blog?category=utrata-blizkogo",
      title:
        "Блог об утрате близкого: поддержка и проработка страхов от Анны Сокольниковой",
      description:
        "Энергопрактик Анна Сокольникова раскрывает темы стабилизации эмоционального состояния, возвращения интереса к жизни при утрате близкого - читать статьи.",
      canon: "https://annasokolnikova.ru/blog?category=utrata-blizkogo",
    },
    {
      id: 41,
      key: "blog?category=vneshnost-i-telo",
      title:
        "Блог о внешности и теле: неприятие себя от Анны Сокольниковой",
      description:
        "Сертифицированный энергопрактик Анна Сокольникова рассказывает об изъянах, вызывающих страдания и комплексах внешнего вида - читать статьи онлайн.",
      canon: "https://annasokolnikova.ru/blog?category=vneshnost-i-telo",
    },
    {
      id: 42,
      key: "blog?category=emotsionalnoe-i-fizicheskoe-sostoianie",
      title:
        "Блог об эмоциональном и физическом состоянии от Анны Сокольниковой",
      description:
        "Анна Сокольникова рассказывает об эмоциональном и физическом состоянии: хроническое напряжение, эмоциональное выгорание и отсутствие интереса к жизни - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog?category=emotsionalnoe-i-fizicheskoe-sostoianie",
    },
    {
      id: 43,
      key: "blog?category=slozhnyi-vybor",
      title:
        "Статьи про сложный выбор от Анны Сокольниковой: внутренние конфликты и страхи и другое",
      description:
        "Анна Сокольникова раскрывает тему ясности в ситуации сложного выбора: поиск и обретение возможностей, принятие решений - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog?category=slozhnyi-vybor",
    },
    {
      id: 44,
      key: "blog?category=channeling",
      title:
        "Ченнелинг от Анны Сокольниковой: определение, привнесение ясности и доверия",
      description:
        "Сертифицированный энергопрактик Анна Сокольникова рассказывает о сеансе ченнелинга с Вашим Высшим Я.",
      canon: "https://annasokolnikova.ru/blog?category=channeling",
    },
    {
      id: 45,
      key: "blog/vrode-vse-khorosho-a-mne-plokho",
      title:
        "Вроде все хорошо, а мне плохо: статья о личностном развитии человека",
      description:
        "Узнайте о том, насколько важно брать ответственность за свои поступки от коуча Анны Сокольниковой. Как построить гармоничные отношения с собой - читать онлайн",
      canon: "https://annasokolnikova.ru/blog/vrode-vse-khorosho-a-mne-plokho",
    },
    {
      id: 46,
      key: "blog/i-net-pokoya",
      title:
        "И нет покоя: Анна Сокольникова о ценности опыта",
      description:
        "Узнайте о новых нейронных связях и их способах появления. Читайте онлайн - как полюбить себя и войти в ресурсное состояние от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/blog/i-net-pokoya",
    },
    {
      id: 47,
      key: "blog/kak-perestat-byt-zhertvoj",
      title:
        "Как перестать быть жертвой от Анны Сокольниковой",
      description:
        "О познании себя истинного, уверенности и внутренних блоках - читать статью онлайн от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/blog/kak-perestat-byt-zhertvoj",
    },
    {
      id: 48,
      key: "blog/kak-ya-uvolilas-s-raboty",
      title:
        "Как я уволилась с работы: статья от Анны Сокольниковой",
      description:
        "Узнайте, как понять свои истинные желания и цели. Анна Сокольникова раскрывает темы внутреннего конфликта, изменениях в жизни и обесценивании.",
      canon: "https://annasokolnikova.ru/blog/kak-ya-uvolilas-s-raboty",
    },
    {
      id: 49,
      key: "blog/polyubi-i-svoyu-ten",
      title:
        "Полюби и свою тень: Анна Сокольникова о принятии недостатков",
      description:
        "Анна Сокольникова раскрывает тему принятия себя такими, какими мы являемся. Как перестать винить себя за ошибки - читать онлайн",
      canon: "https://annasokolnikova.ru/blog/polyubi-i-svoyu-ten",
    },
    {
      id: 50,
      key: "blog/strakh-oshibki",
      title:
        "Страх ошибки: Анна Сокольникова об отношениях родителей к детям",
      description:
        "Узнайте как перестать бояться совершить ошибку от Анны Сокольниковой. Отношения родителей и детей - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog/strakh-oshibki",
    },
    {
      id: 51,
      key: "blog/temnaya-noch-dushi",
      title:
        "Темная ночь души: Анна Сокольникова об эмоциональном состоянии",
      description:
        "Как понять, что Вы проживаете темную ночь Души? Читать статью онлайн о повышенной тревожност и панике от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/blog/temnaya-noch-dushi",
    },
    {
      id: 52,
      key: "store/svecha-s-podmarennikom",
      title:
        "Свеча с подмаренником - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с подмаренником - по выгодной цене от Анны Сокольниковой",
      canon: "https://annasokolnikova.ru/store/svecha-s-podmarennikom",
    },
    {
      id: 53,
      key: "store/svecha-s-rozoi",
      title:
        "Свеча с розой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с розой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-rozoi",
    },
    {
      id: 54,
      key: "store/svecha-s-pionom",
      title:
        "Свеча с пионом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с пионом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-pionom",
    },
    {
      id: 55,
      key: "store/svecha-s-dubom",
      title:
        "Свеча с дубом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с дубом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-dubom",
    },
    {
      id: 56,
      key: "store/svecha-s-kleverom",
      title:
        "Свеча с клевером - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с клевером - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-kleverom",
    },
    {
      id: 57,
      key: "store/svecha-s-berezoi",
      title:
        "Свеча с березой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с березой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-berezoi",
    },
    {
      id: 58,
      key: "store/svecha-s-dushitsei",
      title:
        "Свеча с душицей - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с душицей - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-dushitsei",
    },
    {
      id: 59,
      key: "store/svecha-s-vasilkom",
      title:
        "Свеча с васильком - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с васильком - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-vasilkom",
    },
    {
      id: 60,
      key: "store/svecha-s-limonnikom",
      title:
        "Свеча с лимонником - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с лимонником - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-limonnikom",
    },
    {
      id: 61,
      key: "store/svecha-s-polyniu",
      title:
        "Свеча с полынью - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с полынью - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-polyniu",
    },
    {
      id: 62,
      key: "store/svecha-so-zveroboem",
      title:
        "Свеча со зверобоем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча со зверобоем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-so-zveroboem",
    },
    {
      id: 63,
      key: "store/svecha-s-shalfeem",
      title:
        "Свеча с шалфеем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с шалфеем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-shalfeem",
    },
    {
      id: 64,
      key: "store/svecha-s-lavandoi",
      title:
        "Свеча с лавандой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с лавандой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-lavandoi",
    },
    {
      id: 65,
      key: "store/svecha-s-romashkoi",
      title:
        "Свеча с ромашкой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с ромашкой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-romashkoi",
    },
    {
      id: 66,
      key: "store/svecha-s-miatoi",
      title:
        "Свеча с мятой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Свеча с мятой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/svecha-s-miatoi",
    },
    {
      id: 67,
      key: "store/nabor-liubov-i-otnosheniia",
      title:
        "Любовь и отношения - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Любовь и отношения - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/nabor-liubov-i-otnosheniia",
    },
    {
      id: 68,
      key: "store/nabor-denezhnoe-izobilie",
      title:
        "Денежное изобилие - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Денежное изобилие - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/nabor-denezhnoe-izobilie",
    },
    {
      id: 69,
      key: "store/nabor-energichnost-i-zhiznennaia-sila",
      title:
        "Энергичность и жизненная сила - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Энергичность и жизненная сила - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/nabor-energichnost-i-zhiznennaia-sila",
    },
    {
      id: 70,
      key: "store/nabor-zarozhdenie",
      title:
        "Зарождение - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Зарождение - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/nabor-zarozhdenie",
    },
    {
      id: 71,
      key: "store/nabor-probuzhdenie-pervozdannoi-zhenshchiny",
      title:
        "Пробуждение первозданной женщины - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Пробуждение первозданной женщины - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/nabor-probuzhdenie-pervozdannoi-zhenshchiny",
    },
    {
      id: 72,
      key: "store/nabor-uspekh-i-udacha",
      title:
        "Успех и удача - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Успех и удача - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/nabor-uspekh-i-udacha",
    },
    {
      id: 73,
      key: "store/nabor-intuitsiia-i-doverie-sebe",
      title:
        "Интуиция и доверие себе - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Интуиция и доверие себе - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/nabor-intuitsiia-i-doverie-sebe",
    },
    {
      id: 74,
      key: "store/sergi-kianit-korall",
      title:
        "Серьги  с кианитом и кораллом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги  с кианитом и кораллом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-kianit-korall",
    },
    {
      id: 75,
      key: "store/sergi-kianit-granat",
      title:
        "Серьги с кианитом и гранатом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с кианитом и гранатом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-kianit-granat",
    },
    {
      id: 76,
      key: "store/sergi-barochnyi-zhemchug",
      title:
        "Серьги с барочным жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с барочным жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-barochnyi-zhemchug",
    },
    {
      id: 77,
      key: "store/sergi-kianit-zhemchug",
      title:
        "Серьги с кианитом и барочным жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с кианитом и барочным жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-kianit-zhemchug",
    },
    {
      id: 78,
      key: "store/sergi-iashma",
      title:
        "Серьги с яшмой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с яшмой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-iashma",
    },
    {
      id: 79,
      key: "store/sergi-iashma-zhadeit-zmeevik",
      title:
        "Серьги с яшмой и змеевиком - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с яшмой и змеевиком - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-iashma-zhadeit-zmeevik",
    },
    {
      id: 80,
      key: "store/sergi-barochnyi-zhemchug-s-pozolotom",
      title:
        "Серьги с жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-barochnyi-zhemchug-s-pozolotom",
    },
    {
      id: 81,
      key: "store/sergi-kianit-i-zhemchug",
      title:
        "Серьги с кианитом и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с кианитом и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-kianit-i-zhemchug",
    },
    {
      id: 82,
      key: "store/sergi-snezhnyi-kvarts",
      title:
        "Серьги со снежным кварцем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги со снежным кварцем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-snezhnyi-kvarts",
    },
    {
      id: 83,
      key: "store/sergi-naturalnyi-zhemchug",
      title:
        "Серьги с жемчугом и позолотой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с жемчугом и позолотой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-naturalnyi-zhemchug",
    },
    {
      id: 84,
      key: "store/sergi-lazurit-korall",
      title:
        "Серьги с лазуритом и кораллом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с лазуритом и кораллом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-lazurit-korall",
    },
    {
      id: 85,
      key: "store/sergi-zhemchug-perlamutr",
      title:
        "Серьги с жемчугом и перламутром - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с жемчугом и перламутром - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-zhemchug-perlamutr",
    },
    {
      id: 86,
      key: "store/sergi-korall-govlit",
      title:
        "Серьги с кораллом и говлитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с кораллом и говлитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-korall-govlit",
    },
    {
      id: 87,
      key: "store/sergi-zhemchug-fianity",
      title:
        "Серьги с хлопковым жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с хлопковым жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-zhemchug-fianity",
    },
    {
      id: 88,
      key: "store/sergi-apatit-akvamarin",
      title:
        "Серьги с апатитом и аквамарином - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с апатитом и аквамарином - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-apatit-akvamarin",
    },
    {
      id: 89,
      key: "store/sergi-turmalin-iuvelirnoe-steklo",
      title:
        "Серьги с турмалином - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с турмалином - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-turmalin-iuvelirnoe-steklo",
    },
    {
      id: 90,
      key: "store/sergi-amazonit-lavovyi-kamen",
      title:
        "Серьги с амазонитом и лавовым камнем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с амазонитом и лавовым камнем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-amazonit-lavovyi-kamen",
    },
    {
      id: 91,
      key: "store/sergi-transformer-gematit",
      title:
        "Серьги-трансформер с гематитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги-трансформер с гематитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-transformer-gematit",
    },
    {
      id: 92,
      key: "store/sergi-naturalnyi-zhemchug-perlamutr",
      title:
        "Серьги с перламутром и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с перламутром и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-naturalnyi-zhemchug-perlamutr",
    },
    {
      id: 93,
      key: "store/sergi-iantar-iashma",
      title:
        "Серьги с янтарем и яшмой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с янтарем и яшмой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-iantar-iashma",
    },
    {
      id: 94,
      key: "store/sergi-transformer-khlopkovyi-zhemchug",
      title:
        "Серьги-трансформер с хлопковым жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги-трансформер с хлопковым жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-transformer-khlopkovyi-zhemchug",
    },
    {
      id: 95,
      key:
        "store/sergi-naturalnyi-zhemchug-perlamutr-furnitura-klassa-premium",
      title:
        "Серьги с классическим жемчугом и перламутром - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с классическим жемчугом и перламутром - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-naturalnyi-zhemchug-perlamutr-furnitura-klassa-premium",
    },
    {
      id: 96,
      key: "store/sergi-lavovyi-kamen-khrustal",
      title:
        "Серьги с лавовым камнем и горным хрусталем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с лавовым камнем и горным хрусталем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-lavovyi-kamen-khrustal",
    },
    {
      id: 97,
      key: "store/sergi-lavovyi-kamen-gematit",
      title:
        "Серьги с лавовым камнем и гематитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с лавовым камнем и гематитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-lavovyi-kamen-gematit",
    },
    {
      id: 98,
      key: "store/sergi-barochnyi-zhemchug-furnitura",
      title:
        "Серьги с крупным барочным жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с крупным барочным жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-barochnyi-zhemchug-furnitura",
    },
    {
      id: 99,
      key: "store/sergi-businy-bodkhi",
      title:
        "Серьги с яшмой и бусинами бодхи - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с яшмой и бусинами бодхи - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-businy-bodkhi",
    },
    {
      id: 100,
      key: "store/sergi-biser-iuvelirnoe-steklo",
      title:
        "Позолоченные серьги - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Позолоченные серьги - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-biser-iuvelirnoe-steklo",
    },
    {
      id: 101,
      key: "store/sergi-naturalnyi-zhemchug-korall",
      title:
        "Серьги с жемчугом и кораллом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с жемчугом и кораллом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-naturalnyi-zhemchug-korall",
    },
    {
      id: 102,
      key: "store/sergi-azurit",
      title:
        "Серьги с азуритом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с азуритом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-azurit",
    },
    {
      id: 103,
      key: "store/sergi-zhemchug-korall-govlit",
      title:
        "Серьги с жемчугом, кораллом и говлитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с жемчугом, кораллом и говлитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-zhemchug-korall-govlit",
    },
    {
      id: 104,
      key: "store/sergi-irnimit",
      title:
        "Серьги с ирнимитом и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с ирнимитом и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-irnimit",
    },
    {
      id: 105,
      key: "store/sergi-lavovyi-kamen-gornyi-khrustal",
      title:
        "Серьги с вулканическим камнем и горным хрусталем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с вулканическим камнем и горным хрусталем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-lavovyi-kamen-gornyi-khrustal",
    },
    {
      id: 106,
      key: "store/sergi-gornyi-khrustal-zhemchug",
      title:
        "Серьги с горным хрусталем и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с горным хрусталем и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-gornyi-khrustal-zhemchug",
    },
    {
      id: 107,
      key: "store/sergi-iuvelirnoe-steklo",
      title:
        "Серьги с черным ювелирным стеклом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с черным ювелирным стеклом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-iuvelirnoe-steklo",
    },
    {
      id: 108,
      key: "store/sergi-lazurit-naturalnyi-zhemchug",
      title:
        "Серьги с лазуритом и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с лазуритом и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-lazurit-naturalnyi-zhemchug",
    },
    {
      id: 109,
      key: "store/sergi-agat-krakle",
      title:
        "Серьги с агатом-кракле - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с агатом-кракле - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-agat-krakle",
    },
    {
      id: 110,
      key: "store/sergi-iolit-zhemchug",
      title:
        "Серьги с иолитом и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с иолитом и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-iolit-zhemchug",
    },
    {
      id: 111,
      key: "store/sergi-zhemchug-furnitura",
      title:
        "Позолоченные серьги с барочным жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Позолоченные серьги с барочным жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-zhemchug-furnitura",
    },
    {
      id: 112,
      key: "store/sergi-furnitura-klassa-premium",
      title:
        "Позолоченные серьги-кольца - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Позолоченные серьги-кольца - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-furnitura-klassa-premium",
    },
    {
      id: 113,
      key: "store/sergi-zhemchug-furnitura-klassa-premium",
      title:
        "Серьги с крупным жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с крупным жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-zhemchug-furnitura-klassa-premium",
    },
    {
      id: 114,
      key: "store/sergi-gornyi-khrustal-elementy-kozhi",
      title:
        "Серьги с горным хрусталем и кожаными вставками - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Серьги с горным хрусталем и кожаными вставками - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/sergi-gornyi-khrustal-elementy-kozhi",
    },
    {
      id: 115,
      key: "store/iashma-pirit-businy-bodkhi",
      title:
        "Браслет с яшмой и пиритом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с яшмой и пиритом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/iashma-pirit-businy-bodkhi",
    },
    {
      id: 116,
      key: "store/shungit-lavovyi-kamen",
      title:
        "Браслет с шунгитом и лавовым камнем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с шунгитом и лавовым камнем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/shungit-lavovyi-kamen",
    },
    {
      id: 117,
      key: "store/obsidian-govlit",
      title:
        "Браслет с обсидианом и говлитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с обсидианом и говлитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/obsidian-govlit",
    },
    {
      id: 118,
      key: "store/shungit-lavovyi-kamen-sodalit",
      title:
        "Браслет с шунгитом, лавовым камнем и содалитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с шунгитом, лавовым камнем и содалитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/shungit-lavovyi-kamen-sodalit",
    },
    {
      id: 119,
      key: "store/lavovyi-kamen-gematit",
      title:
        "Браслет с лавовым камнем и гематитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с лавовым камнем и гематитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/lavovyi-kamen-gematit",
    },
    {
      id: 120,
      key: "store/obsidian-shungit",
      title:
        "Браслет с обсидианом и шунгитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с обсидианом и шунгитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/obsidian-shungit",
    },
    {
      id: 121,
      key: "store/shungit-obsidian",
      title:
        "Браслет с шунгитом и обсидианом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с шунгитом и обсидианом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/shungit-obsidian",
    },
    {
      id: 122,
      key: "store/iashma-shungit",
      title:
        "Браслет с яшмой и шунгитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с яшмой и шунгитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/iashma-shungit",
    },
    {
      id: 123,
      key: "store/apatit-akvamarin",
      title:
        "Браслет и серьги с апатитом и аквамарином - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет и серьги с апатитом и аквамарином - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/apatit-akvamarin",
    },
    {
      id: 124,
      key: "store/iashma-kostianye-i-dereviannye-businy",
      title:
        "Колье и браслет с яшмой и янтарем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье и браслет с яшмой и янтарем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/iashma-kostianye-i-dereviannye-businy",
    },
    {
      id: 125,
      key: "store/iashma-businy-bodkhi",
      title:
        "Браслет и серьги с яшмой и бусинами бодхи - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет и серьги с яшмой и бусинами бодхи - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/iashma-businy-bodkhi",
    },
    {
      id: 126,
      key: "store/iolit-zhemchug",
      title:
        "Браслет и серьги с иолитом и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет и серьги с иолитом и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/iolit-zhemchug",
    },
    {
      id: 127,
      key: "store/gornyi-khrustal-elementy-kozhi",
      title:
        "Колье и серьги с горным хрусталем и кожаными вставками - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье и серьги с горным хрусталем и кожаными вставками - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/gornyi-khrustal-elementy-kozhi",
    },
    {
      id: 128,
      key: "store/amazonit-lavovyi-kamen",
      title:
        "Колье и серьги с амазонитом и кожаными вставками - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье и серьги с амазонитом и кожаными вставками - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/amazonit-lavovyi-kamen",
    },
    {
      id: 129,
      key: "store/braslet-korall-barochnyj-zhemchug",
      title:
        "Браслет с кораллом, жемчугом и амазонитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с кораллом, жемчугом и амазонитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-korall-barochnyj-zhemchug",
    },
    {
      id: 130,
      key: "store/braslet-zhemchug-iolit",
      title:
        "Браслет с жемчугом и иолитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с жемчугом и иолитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-zhemchug-iolit",
    },
    {
      id: 131,
      key: "store/braslet-yashma-businy-bodkhi",
      title:
        "Браслет с яшмой и агатом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с яшмой и агатом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-yashma-businy-bodkhi",
    },
    {
      id: 132,
      key: "store/braslet-czitrin-yuvelirnoe-steklo",
      title:
        "Браслет с цитрином - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с цитрином - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-czitrin-yuvelirnoe-steklo",
    },
    {
      id: 133,
      key: "store/braslet-apatit-akvamarin",
      title:
        "Браслет с апатитом и аквамарином - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с апатитом и аквамарином - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-apatit-akvamarin",
    },
    {
      id: 134,
      key: "store/braslet-korall-govlit",
      title:
        "Браслет с кораллом, содалитом и цитрином - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с кораллом, содалитом и цитрином - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-korall-govlit",
    },
    {
      id: 135,
      key: "store/braslet-barochnyj-zhemchug-lazurit",
      title:
        "Браслет с жемчугом и лазуритом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с жемчугом и лазуритом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-barochnyj-zhemchug-lazurit",
    },
    {
      id: 136,
      key: "store/braslet-lazurit-flyuorit",
      title:
        "Браслет с лазуритом и амазонитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с лазуритом и амазонитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-lazurit-flyuorit",
    },
    {
      id: 137,
      key: "store/braslet-lazurit-korall",
      title:
        "Браслет с лазуритом и кораллом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с лазуритом и кораллом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-lazurit-korall",
    },
    {
      id: 138,
      key: "store/braslet-yashma-kakholong",
      title:
        "Браслет с яшмой и кахолонгом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с яшмой и кахолонгом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-yashma-kakholong",
    },
    {
      id: 139,
      key: "store/braslet-kostyanye-i-derevyannye-businy",
      title:
        "Браслет с яшмой и бусинами бодхи - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с яшмой и бусинами бодхи - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-kostyanye-i-derevyannye-businy",
    },
    {
      id: 140,
      key: "store/braslet-pirit",
      title:
        "Браслет с пиритом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Браслет с пиритом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/braslet-pirit",
    },
    {
      id: 141,
      key: "store/kole-kianit-korall",
      title:
        "Колье с кианитом и горным хрусталем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с кианитом и горным хрусталем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-kianit-korall",
    },
    {
      id: 142,
      key: "store/kole-yashma-agat",
      title:
        "Колье с яшмой и агатом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с яшмой и агатом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-yashma-agat",
    },
    {
      id: 143,
      key: "store/kole-khrizopraz",
      title:
        "Колье с хризопразом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с хризопразом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-khrizopraz",
    },
    {
      id: 144,
      key: "store/kole-yashma-zmeevik",
      title:
        "Колье с яшмой и змеевиком - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с яшмой и змеевиком - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-yashma-zmeevik",
    },
    {
      id: 145,
      key: "store/kole-kiant-khrustal",
      title:
        "Колье с киантом и горным хрусталем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с киантом и горным хрусталем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-kiant-khrustal",
    },
    {
      id: 146,
      key: "store/kole-vulkanicheskaya-lava",
      title:
        "Колье со змеевиком и лавовым камнем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье со змеевиком и лавовым камнем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-vulkanicheskaya-lava",
    },
    {
      id: 147,
      key: "store/kole-kianit",
      title:
        "Колье с кианитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с кианитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-kianit",
    },
    {
      id: 148,
      key: "store/kole-korall",
      title:
        "Колье с кораллом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с кораллом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-korall",
    },
    {
      id: 149,
      key: "store/kole-naturalnyj-zhemchug",
      title:
        "Колье с жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-naturalnyj-zhemchug",
    },
    {
      id: 150,
      key: "store/kole-agat-korall",
      title:
        "Колье с агатом и кораллом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с агатом и кораллом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-agat-korall",
    },
    {
      id: 151,
      key: "store/kole-snezhnyj-kvarcz",
      title:
        "Колье со снежным кварцем и хризопразом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье со снежным кварцем и хризопразом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-snezhnyj-kvarcz",
    },
    {
      id: 152,
      key: "store/kole-naturalnyj-i-barochnyj-zhemchug",
      title:
        "Колье с барочным жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с барочным жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-naturalnyj-i-barochnyj-zhemchug",
    },
    {
      id: 153,
      key: "store/kole-amazonit",
      title:
        "Колье с амазонитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с амазонитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-amazonit",
    },
    {
      id: 154,
      key: "store/kole-rakushki",
      title:
        "Колье с жемчугом и ракушками - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с жемчугом и ракушками - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-rakushki",
    },
    {
      id: 155,
      key: "store/kole-zhemchug-rakushki",
      title:
        "Колье с жемчугом и перламутром - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с жемчугом и перламутром - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-zhemchug-rakushki",
    },
    {
      id: 156,
      key: "store/kole-yuvelirnoe-steklo",
      title:
        "Колье с сияющими бусинами - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с сияющими бусинами - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-yuvelirnoe-steklo",
    },
    {
      id: 157,
      key: "store/kole-iolit-lazurit",
      title:
        "Колье с иолитом и лазуритом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с иолитом и лазуритом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-iolit-lazurit",
    },
    {
      id: 158,
      key: "store/kole-gornyj-khrustal",
      title:
        "Колье с горным хрусталем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с горным хрусталем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-gornyj-khrustal",
    },
    {
      id: 159,
      key: "store/kole-transformer-zhemchug",
      title:
        "Колье-трансформер с жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье-трансформер с жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-transformer-zhemchug",
    },
    {
      id: 160,
      key: "store/kole-barochnyj-zhemchug",
      title:
        "Колье с барочным и классическим жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с барочным и классическим жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-barochnyj-zhemchug",
    },
    {
      id: 161,
      key: "store/kole-kianit-sodalit",
      title:
        "Колье с кианитом и содалитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с кианитом и содалитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-kianit-sodalit",
    },
    {
      id: 162,
      key: "store/kole-zhemchug",
      title:
        "Позолоченное колье с жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Позолоченное колье с жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-zhemchug",
    },
    {
      id: 163,
      key: "store/kole-barochnyj-i-naturalnyj-zhemchug",
      title:
        "Позолоченное колье с барочным жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Позолоченное колье с барочным жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-barochnyj-i-naturalnyj-zhemchug",
    },
    {
      id: 164,
      key: "store/kole-businy-iz-rakushek",
      title:
        "Колье с кианитом и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с кианитом и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-businy-iz-rakushek",
    },
    {
      id: 165,
      key: "store/kole-transformer-gornyj-khrustal",
      title:
        "Колье-трансформер с горным хрусталем и жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье-трансформер с горным хрусталем и жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-transformer-gornyj-khrustal",
    },
    {
      id: 166,
      key: "store/kole-barochnyj-zhemchug-pozoloto",
      title:
        "Многоярусное колье с жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Многоярусное колье с жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-barochnyj-zhemchug-pozoloto",
    },
    {
      id: 167,
      key: "store/kole-kostyanye-businy",
      title:
        "Колье с говлитом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с говлитом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-kostyanye-businy",
    },
    {
      id: 168,
      key: "store/kole-transformer-korall",
      title:
        "Колье-трансформер с кораллом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье-трансформер с кораллом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-transformer-korall",
    },
    {
      id: 169,
      key: "store/kole-yantar",
      title:
        "Колье с янтарем - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с янтарем - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-yantar",
    },
    {
      id: 170,
      key: "store/kole-transformer-ametist",
      title:
        "Колье-трансформер с аметистом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье-трансформер с аметистом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-transformer-ametist",
    },
    {
      id: 171,
      key: "store/kole-transformer-yashma",
      title:
        "Колье-трансформер с яшмой - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье-трансформер с яшмой - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/kole-transformer-yashma",
    },
    {
      id: 172,
      key: "store/dlinoe-kole-gemchug",
      title:
        "Длинное колье с жемчугом - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Длинное колье с жемчугом - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/dlinoe-kole-gemchug",
    },
    {
      id: 173,
      key: "store/govlit-bodhi",
      title:
        "Колье с говлитом и бусинами бодхи - закажите лучшие энергетические помощники от Анны Сокольниковой",
      description:
        "Поддержите свое духовное развитие, благополучие и радость с помощью товара Колье с говлитом и бусинами бодхи - по выгодной цене от Анны Сокольниковой.",
      canon: "https://annasokolnikova.ru/store/govlit-bodhi",
    },
    {
      id: 174,
      key: "blog/protivopologno",
      title:
        "Все противоположно - статья от Анны Сокольниковой",
      description:
        "Узнайте, как освободить себя от негативной энергии от коуча Анны Сокольниковой. Все о противоположности в вещах и словах - читать оналйн.",
      canon: "https://annasokolnikova.ru/blog/protivopologno",
    },
    {
      id: 175,
      key: "blog/kak-strakhi-ubivayut-mechty",
      title:
          "Страхи убивают мечты - статья от Анны Сокольниковой",
      description:
          "Узнайте, как страхи убивают ваши мечты от коуча Анны Сокольниковой. Как начать мечтать и не боються - читать оналйн.",
      canon: "https://annasokolnikova.ru/blog/kak-strakhi-ubivayut-mechty",
    },
    {
      id: 176,
      key: "blog/kak-ya-polyubila-svoyo-telo",
      title:
          "Как полюбить свое тело - статья от Анны Сокольниковой",
      description:
          "Узнайте, как полючить себя и свое тело от коуча Анны Сокольниковой. Читайте оналйн про любовь и уважение к себе.",
      canon: "https://annasokolnikova.ru/blog/kak-ya-polyubila-svoyo-telo",
    },
    {
      id: 177,
      key: "blog/chego-zhelaete",
      title:
          "Что вы желаете - статья от Анны Сокольниковой",
      description:
          "Узнайте, почему вы запрещаете себе мечтать от коуча Анны Сокольниковой. Читать онлайн - как начать мечтать и не бояться.",
      canon: "https://annasokolnikova.ru/blog/chego-zhelaete",
    },
    {
      id: 178,
      key: "blog/chto-pomozhet-pri-utraty",
      title:
          "Как справить с утратой - статья Анны Сокольниковой",
      description:
          "Узнайте, как спарвить с утратой близкого от коуча Анны Сокольниковой. Статья о том, как пережить смерть родных - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog/chto-pomozhet-pri-utraty",
    },
    {
      id: 179,
      key: "blog/kto-kogo-vampirit",
      title:
          "Статья о любви к себе от Анны Сокольниковой",
      description:
          "Узнайте, кто кого вампирит из статьи от коуча Анны Сокольникова. Как любить себя и ставить на первое место - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog/kto-kogo-vampirit",
    },
    {
      id: 180,
      key: "blog/praktika-yasno",
      title:
          "Практика Ясность - разбор от Анны Сокольниковой",
      description:
          "Узнайте, как использовать практику Ясность от коуча Анны Сокольниковой. Разрешите сложные ситуации благодаря практики прямо сейчас!",
      canon: "https://annasokolnikova.ru/blog/praktika-yasno",
    },
    {
      id: 181,
      key: "blog/prisutstve-znaki",
      title:
          "Присутствие знаков в жизни - статья от Анны Сокольниковой",
      description:
          "Разбор присутсвия в жизни знаков от коуча Анны Сокольниковой. Узнайте, как распознать знаки судьбы в жизни прямо сейчас!",
      canon: "https://annasokolnikova.ru/blog/prisutstve-znaki",
    },
    {
      id: 182,
      key: "blog/pro-poiski-prednaznacheniya",
      title:
          "Поиск предназначения - читать от Анны Сокольниковой",
      description:
          "Узнайте, как найти свой талант в статье от коуча Анны Сокольниковой. Как раскрыть свой потенциал - чтать оналйн.",
      canon: "https://annasokolnikova.ru/blog/pro-poiski-prednaznacheniya",
    },
    {
      id: 183,
      key: "blog/kak-realizovat-prednaznachenie",
      title:
          "Как реализовать свое предназначение от Анны Сокольниковой",
      description:
          "Узнайте, как можно реализовать свой талант в статье от Анны Сокольниковой. Все о пути и предназначении - читать оналйн.",
      canon: "https://annasokolnikova.ru/blog/kak-realizovat-prednaznachenie",
    },
    {
      id: 184,
      key: "blog/zarabativau",
      title:
          "Как начат выбирать себя - читать от Анны Сокольниковой",
      description:
          "Статья о том, как начать жить для себя от Анны Сокольниковой. Какие вопросы помогут Вам разорать в себе - читать оналйн. ",
      canon: "https://annasokolnikova.ru/blog/zarabativau",
    },
    {
      id: 185,
      key: "blog/kogda-pitautsya-pochinit",
      title:
          "Когда нас пытаются починить - статья от Анны Сокольниковой",
      description:
          "Читайте статью на тему Когда нас пытаются починить от энергопрактика Анны Сокольниковой. Научитесь доверять себе с коучем в России.",
      canon: "https://annasokolnikova.ru/blog/kogda-pitautsya-pochinit",
    },
    {
      id: 186,
      key: "blog/iz-chego-vse-voznikaet",
      title:
          "Из чего все возникает - разбирается Анна Сокольникова",
      description:
          "Узнайте из чего все возникает в мире от Анны Сокольниковой. Информация о принятии своих действий - читать онлайн. ",
      canon: "https://annasokolnikova.ru/blog/iz-chego-vse-voznikaet",
    },
    {
      id: 187,
      key: "blog/rezonans-chumana",
      title:
          "Резонанс Шумана - что это такое от Анны Сокольниковой",
      description:
          "Узнайте, что такое резонанс Шумана от энергокоуча Анны Скольниковой. Шкала вибраций Земли и резонанс Шумана - читать онлайн.",
      canon: "https://annasokolnikova.ru/blog/rezonans-chumana",
    },
    {
      id: 188,
      key: "blog/kogda-prigmet-veru",
      title:
          "Вера, когда выгодно - что это значит от Анны Сокольниковой",
      description:
          "Узнайте, что значит выгодная вера от Анны Сокольниковой. Вера, когда прижмет - статья о выгодной вере.",
      canon: "https://annasokolnikova.ru/blog/kogda-prigmet-veru",
    },
  ],
};

// fetch meta-all
// array = []
// for (let i = 0; i < data.length; i++) {
//   array.push({
//       'id': i + 52,
//       'key': `store/${data[i].en_title}`,
//       'title': `${data[i].title} - закажите лучшие энергетические помощники от Анны Сокольниковой`,
//       'description': `Поддержите свое духовное развитие, благополучие и радость с помощью товара ${data[i].title} - по выгодной цене от Анны Сокольниковой`
//   })
// }
// array

