import React, { useEffect } from "react";
import Form from "../components/Form/Form";

export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <div className="faq_header">
        <p>Записаться на консультацию / задать вопрос</p>
      </div>
      <Form displayTitle={false} />
    </section>
  );
};
