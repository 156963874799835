import { Reasons } from "../components/Reasons/Reasons";
import { Coaching } from "../components/Coaching/Coaching";
import { Tab } from "../components/Tab/Tab";
import { FAQ } from "../components/FAQ/FAQ";
import CertificatesCarousel from "../components/Carousel/Carousel";
import { Photo } from "../components/Photo/Photo";
import Form from "../components/Form/Form";
import { Testimonial } from "../components/Testimonial/Testimonial";
import "../App.css";
import React from "react";
import BannerCarousel from "../components/banner_carousel/Carousel";

export const Main = () => {
  return (
    <>
      <Photo />
      <Reasons />
      <Coaching />
      <BannerCarousel />
      <Tab />
      <CertificatesCarousel />
      <Form />
      <div className="container testimonials_wrapper">
        <Testimonial
          name={"Марина:"}
          text={
            "Анна, хочу поблагодарить за проведенную сессию. Очень глубоко проработали темы, о которых я даже не думала. Загрузки просто фантастические! Встроились замечательно и уже дают результаты. Сканирование по чакрам тоже очень впечатлило. Все в точку! Благодарю вас! "
          }
        />
        <Testimonial
          name={"Ольга:"}
          text={
            "Анна, благодарю за сессию. Убеждения выявлены и завершены на 100%. Классные загрузки, прочувствовала каждой клеточкой. Ощущение, что из самых глубин доставали и вычищали. А после – чувства легкости и свободы. Очень тонкий подход. Рада знакомству с вами."
          }
        />
        <Testimonial
          name={"Станислав:"}
          text={
            "Мне помогло безусловное доверие к моему проводнику – Ане. После первой сессии я чувствовал, как будто мой мозг получил отличный массаж. Ощущение, что готов силой мысли сдвинуть стул) Я не мог отказаться от такого опыта, и пришел на сессию еще раз. Сюрпризов было еще больше. Спасибо большое!"
          }
        />
      </div>
      <FAQ />
    </>
  );
};
