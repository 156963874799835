import React from "react";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./css/Card.css";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/requests";

export const Card = ({ img, title, href, description, price }) => {
  return (
    <>
      <div className={"wrapper_card"}>
        <Link to={href} style={{ textDecoration: "none" }}>
          <div className={"img_card"}>
            <img
              style={{ height: price != undefined ? "350px" : "" }}
              src={`${baseUrl}${img}`}
            />
          </div>
        </Link>

        <div className={"wrapper_text_card"}>
          <Link to={href} style={{ textDecoration: "none" }}>
            <div
              className={"header_card"}
              style={{ marginBottom: price != undefined ? "40px" : "" }}
            >
              {title} ...
            </div>
          </Link>

          {description != undefined ? (
            <div className={"text_card"}>
              <p>“{description} ...</p>
            </div>
          ) : (
            <div />
          )}
          <Link to={href} style={{ textDecoration: "none" }}>
            <div className={"read_more-blog"}>
              <span>Далее</span>
            </div>
          </Link>
          {price != undefined ? (
            <p className="price_card">{price.replace(".00", "")} ₽</p>
          ) : (
            <p />
          )}
        </div>
      </div>
    </>
  );
};
