import React from "react";

export const ErrorNoFount = () => {
    return (
        <h1 style={{textAlign: "center", padding: "10% 0"}}>
            <h1 style={{fontSize: "2em"}}>404</h1>
            Страница не найдена
        </h1>
    );
};
